import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

function App() {

  const Redirect = () => {
    useEffect(() => {

      let { pathname, search } = window?.location
      window.location.assign(`https://thegranday.com${pathname}${search}`);
    }, [])
  }

  return (
    <>
      <BrowserRouter>
            <Routes>
                <Route path="*" element={<Redirect to="/" />} />
            </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
